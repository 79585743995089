<template>
  <div class="siemensIndex" v-title="'官方课程'">
    <section class="sec1">
      <div class="pc-main">
        <div class="btn-link">官方课程报名网页</div>
        <button class="btn-primary" @click="showSignIn=true">立即报名</button>
        <a
          class="info"
          target="_blank"
          href="http://peixun.insupai.com/GongKongSuPai/train/TrainBaseList"
        >
          <img src="static/pc/siemens/info.png" alt="" />
        </a>
      </div>
    </section>
    <section class="sec2">
      <div class="pc-main">
        <button class="btn-primary" @click="showSignIn=true">获取课程资料</button>
      </div>
    </section>
    <section class="sec3"></section>
    <section class="sec4">
      <div class="pc-main">
        <button class="btn-primary" @click="showTrial=true">试听课预约入口</button>
      </div>
    </section>
    <section class="sec5">
      <div class="pc-main swiper-main">
        <swiper ref="swiper" class="swiper-box" :options="swiperOptions">
          <swiper-slide v-for="(item,index) in team" :key="index">
            <dl class="member" v-for="(person,idx) in item" :key="person.name+idx">
              <dt>
                <img :src="person.pic" v-lazy="person.pic" :alt="person.name" />
              </dt>
              <dd>
                <div class="name">{{person.name}}</div>
                <div class="title">{{person.title}}</div>
              </dd>
            </dl>
          </swiper-slide>
        </swiper>
        <div class="btn btn-prev"></div>
        <div class="btn btn-next"></div>
      </div>
    </section>
    <section class="sec6"></section>
    <section class="sec7"></section>
    <section class="sec8">
      <div class="pc-main lesson-list">
        <div class="lesson-item">
          <div class="title">电工 / 维护 / 调试</div>
          <img src="static/siemens/role_1.png" alt="" class="role" />
          <div class="summary">
            具备一定的电气基础，想要提升自己， 转岗做工程师，在职业上有所突破
          </div>
          <div class="detail">
            <div class="title">电工 / 维护 / 调试</div>
            <p class="desc">
              进行系统性的知识学习<br />由浅入深，逐步掌握PLC技能<br />理论和实践结合，边学边练<br />有名师指导，帮助夯实基础<br />可以进行项目实操，学以致用
            </p>
          </div>
        </div>
        <div class="lesson-item">
          <div class="title">电气工程师</div>
          <img src="static/siemens/role_2.png" alt="" class="role" />
          <div class="summary">
            在编程调试上巩固提升，迎接更大的挑战 突破职业瓶颈
          </div>
          <div class="detail">
            <div class="title">电气工程师</div>
            <p class="desc">
              原厂大咖讲解，深入了解产品<br />经典案例分析，掌握实战技巧<br />与导师在线交流，探讨知识细节<br />系统化掌握标准化编程<br />获得认证证书，拓展职业边界
            </p>
          </div>
        </div>
        <div class="lesson-item">
          <div class="title">在校学生/应届毕业生</div>
          <img src="static/siemens/role_3.png" alt="" class="role" />
          <div class="summary">
            学习电气/机电专业，想往电气自动化方向发展，打破“毕业即失业”的魔咒
          </div>
          <div class="detail">
            <div class="title">在校学生/应届毕业生</div>
            <p class="desc">
              掌握最新、最全的实用型PLC知识<br />获得珍贵的原厂全套学习资料<br />充分利用时间，提高就业竞争力<br />与名师在线交流，夯实学习基础<br />获得认证证书，在简历上脱颖而出
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="sec9">
      <div class="pc-main">
        <swiper ref="slider" class="slider-wrapper" :options="sliderOptions">
          <swiper-slide v-for="(item ,index) in chats" :key="index">
            <img class="chat" :src="item" v-lazy="item" ref="chat" @click="preview(index)" alt="">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <section class="sec10">
      <div class="pc-main">
        <form class="form">
          <div class="form-item">
            <input class="input" name="Name" type="text" placeholder="姓名" v-model="name" />
          </div>
          <div class="form-item">
            <input
              class="input"
              name="Profession"
              type="text"
              v-model="job"
              placeholder="职业"
            />
          </div>
          <div class="form-item">
            <input class="input" name="Phone" type="text" v-model="phone" placeholder="手机" />
          </div>
          <div class="btn-submit" @click="submit(1)">立即报名</div>
        </form>
      </div>
    </section>
    <div class="mask" v-if="showSignIn || showTrial"></div>
    <div class="dialog-sign" v-show="showSignIn">
      <div class="dialog-head">
        <i class="icon-close" @click="showSignIn = false">×</i>
      </div>
      <div class="dialog-body">
        <form class="form">
          <div class="form-item">
            <input class="input" name="Name" type="text" placeholder="姓名" v-model="name" />
          </div>
          <div class="form-item">
            <input
              class="input"
              name="Profession"
              type="text"
              v-model="job"
              placeholder="职业"
            />
          </div>
          <div class="form-item">
            <input class="input" name="Phone" type="text" v-model="phone" placeholder="手机" />
          </div>
          <div class="btn-submit" @click="submit(1)">立即报名</div>
          <p class="desc">
            报名成功后，5个工作日内将有官方客服对您进行电话回访
            请认准西门子官方课程授权合作伙伴“工业速派”，切勿轻信
            其它非授权机构。您的预留的信息将严格保密，敬请放心。
          </p>
        </form>
        <img class="qrcode" src="static/pc/siemens/dialog1.png" alt="" />
      </div>
    </div>
    <div class="dialog-appointment" v-show="showTrial">
      <div class="dialog-head">
        <i class="icon-close" @click="showTrial=false">×</i>
      </div>
      <div class="dialog-body">
        <form class="form">
          <div class="form-item">
            <input class="input" name="Name" type="text" placeholder="姓名" v-model="name" />
          </div>
          <div class="form-item">
            <input
              class="input"
              name="Profession"
              type="text"
              v-model="job"
              placeholder="职业"
            />
          </div>
          <div class="form-item">
            <input class="input" name="Phone" type="text" v-model="phone" placeholder="手机" />
          </div>
          <div class="btn-submit" @click="submit(2)">预约试听</div>
        </form>
        <p class="desc">
          报名成功后，5个工作日内将有官方客服对您进行电话回访<br />请认准西门子官方课程授权合作伙伴“工业速派”，切勿轻信其它非授权机构<br />您的预留的信息将严格保密，敬请放心
        </p>
        <img class="logo" src="static/pc/siemens/dialog2.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import validate from "@/common/validate.js";
import {
  SignUp,
  getLocation
} from "@/common/api.js";
let returnCitySN = window.returnCitySN;
// import $ from "jquery";
import "swiper/dist/css/swiper.min.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      name: "",
      job: "",
      phone: "",
      cityData: {},
      showSignIn:false,
      showTrial:false,
      swiperOptions: {
        autoplay: 3000,
        loop: true,
        nextButton:'.btn-next',
        prevButton:'.btn-prev',
      },
      sliderOptions: {
        autoplay: 3000,
        loop: true,
        pagination:".swiper-pagination",
      },
      team:[
        [
          {
            name:'毕学武',
            title:'主任工程师',
            pic:require('static/siemens/team/毕学武-主任工程师.png')
          },
          {
            name:'陈华',
            title:'主任工程师',
            pic:require('static/siemens/team/陈华-主任工程师.png')
          },
          {
            name:'杜立军',
            title:'',
            pic:require('static/siemens/team/杜立军.png')
          },
          {
            name:'段礼才',
            title:'主任工程师',
            pic:require('static/siemens/team/段礼才-主任工程师.png')
          },
          {
          name:'樊明霞',
          title:'高级工程师',
          pic:require('static/siemens/team/樊明霞-高级工程师.png')
        },
        {
          name:'房丁',
          title:'高级工程师',
          pic:require('static/siemens/team/房丁-高级工程师.png')
        },
        {
          name:'郭开诚',
          title:'主任',
          pic:require('static/siemens/team/郭开诚-主任.png')
        },
        {
          name:'黄文钰',
          title:'专家',
          pic:require('static/siemens/team/黄文钰-专家.png')
        }
        ],
        [{
          name:'雷鸣',
          title:'专家',
          pic:require('static/siemens/team/雷鸣-专家.png')
        },
        {
          name:'李伟',
          title:'高级工程师',
          pic:require('static/siemens/team/李伟-高级工程师.png')
        },
        {
          name:'李晓炜',
          title:'高级工程师',
          pic:require('static/siemens/team/李晓炜-高级工程师.png')
        },
        {
          name:'李欣然',
          title:'高级工程师',
          pic:require('static/siemens/team/李欣然-高级工程师.png')
        },{
          name:'刘书智',
          title:'专家',
          pic:require('static/siemens/team/刘书智-专家.png')
        },
        {
          name:'马天祥',
          title:'主任工程师',
          pic:require('static/siemens/team/马天祥-主任工程师.png')
        },
        {
          name:'庞开航',
          title:'高级工程师',
          pic:require('static/siemens/team/庞开航-高级工程师.png')
        },
        {
          name:'任俊峰',
          title:'高级工程师',
          pic:require('static/siemens/team/任俊峰-高级工程师.png')
        }],
        [{
          name:'任俊锋',
          title:'',
          pic:require('static/siemens/team/任俊锋.png')
        },
        {
          name:'王广辉',
          title:'高级工程师',
          pic:require('static/siemens/team/王广辉-高级工程师.png')
        },
        {
          name:'徐善海',
          title:'高级工程师',
          pic:require('static/siemens/team/徐善海-高级工程师.png')
        },
        {
          name:'殷悦',
          title:'资深工程师',
          pic:require('static/siemens/team/殷悦-资深工程师.png')
        },{
          name:'张龙',
          title:'高级工程师',
          pic:require('static/siemens/team/张龙-高级工程师.png')
        },{
          name:'张鹏飞',
          title:'高级工程师',
          pic:require('static/siemens/team/张鹏飞-高级工程师.png')
        },{
          name:'张腾',
          title:'高级工程师',
          pic:require('static/siemens/team/张腾-高级工程师.png')
        },{
          name:'张雪亮',
          title:'专家',
          pic:require('static/siemens/team/张雪亮-专家.png')
        }],
        [{
          name:'张占领',
          title:'主任工程师',
          pic:require('static/siemens/team/张占领-主任工程师.png')
        },{
          name:'张忠权',
          title:'主任工程师',
          pic:require('static/siemens/team/张忠权-主任工程师.png')
        },{
          name:'朱飞翔',
          title:'主任工程师',
          pic:require('static/siemens/team/朱飞翔-主任工程师.png')
        },{
          name:'朱玮',
          title:'高级工程师',
          pic:require('static/siemens/team/朱玮-高级工程师.png')
        }],
      ],
      imgs:{
        sec1:require('static/pc/siemens/sec_1.png'),
        sec2:require('static/pc/siemens/sec_2.png'),
        sec3:require('static/pc/siemens/sec_3.png'),
        sec4:require('static/pc/siemens/sec_4.png'),
        sec6:require('static/pc/siemens/sec_6.png'),
        sec7:require('static/pc/siemens/sec_7.png'),
        sec9:require('static/pc/siemens/sec_9.png'),
        sec10:require('static/pc/siemens/sec_10.png'),
      },
      chats:[
        require('static/siemens/chat_1.png'),
        require('static/siemens/chat_2.png'),
        require('static/siemens/chat_3.png'),
      ]
    };
  },
  created() {
    if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        this.$router.replace('/siemens');
      }
  },
  mounted() {
    this.pageInit();
  },
  methods: {
    //获取IP所在地
    pageInit() {
      // let that = this;
      getLocation().then(r=>{
        console.log(r);
        this.cityData = {
          Province: r.content.address_detail.province,
          City: r.content.address_detail.city,
        };
      })
      // $.ajax({
      //   url: "https://api.map.baidu.com/location/ip?ak=ia6HfFL660Bvh43exmH9LrI6",
      //   type: "POST",
      //   dataType: "jsonp",
      //   success: function(data) {
      //     //获取城市
      //     that.cityData = {
      //       Province: data.content.address_detail.province,
      //       City: data.content.address_detail.city,
      //     };
      //   },
      // });
    },
    //提交
    submit(type) {
      if (this.job.trim() == "") {
        this.$toast("请输入姓名");
        return false;
      }
      if (this.job.trim() == "") {
        this.$toast("请输入职业");
        return false;
      }
      if (this.phone.trim() == "") {
        this.$toast("请输入手机号");
        return false;
      }
      if (!validate.phone(this.phone)) {
        this.$toast("手机号格式不正确");
        return false;
      }
      let params = {
        Name: this.name,
        Phone: this.phone,
        Profession: this.job,
        SignType:type,
        ClientIp: returnCitySN["cip"],
        ...this.cityData,
      };
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        params["ClientType"] = 2;
      } else {
        params["ClientType"] = 1;
      }
      SignUp(params).then((r) => {
        this.$toast(r.Message);
        this.showSignIn=false;
        this.showTrial=false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.siemensIndex {
  position: relative;
  .sec1 {
    width: 100%;
    height: 858px;
    background: url(~static/pc/siemens/sec_1.png) no-repeat center top;
    .btn-link {
      position: absolute;
      left: 254px;
      top: 60px;
      font-size: 18px;
      line-height: 36px;
      color: #333;
      cursor: pointer;
      font-weight: bold;
    }
    .info {
      position: absolute;
      right: 0;
      top: 500px;
      width: 605px;
      height: 400px;
      cursor: pointer;
    }

    .info img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .btn-primary {
      position: absolute;
      left: 0;
      top: 583px;
      width: 240px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      border-radius: 4px;
      background-color: #ff7e16;
      font-size: 28px;
      color: #fff;
      cursor: pointer;
    }
    .btn-primary:active {
      background-color: #ff6616;
    }
  }

  .sec2 {
    width: 100%;
    height: 868px;
    background: url(~static/pc/siemens/sec_2.png) no-repeat center top;
    .btn-primary {
      position: absolute;
      left: 50%;
      top: 754px;
      width: 180px;
      height: 48px;
      margin-left: -90px;
      line-height: 48px;
      text-align: center;
      border-radius: 48px;
      background-color: #ff7e16;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }

    .btn-primary:active {
      background-color: #ff6616;
    }
  }

  .sec3 {
    width: 100%;
    height: 1092px;
    background: url(~static/pc/siemens/sec_3.png) no-repeat center top;
  }

  .sec4 {
    width: 100%;
    height: 881px;
    background: url(~static/pc/siemens/sec_4.png) no-repeat center top;
    .btn-primary {
      position: absolute;
      left: 65px;
      top: 621px;
      width: 180px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 48px;
      background-color: #ff7e16;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }

    .btn-primary:active {
      background-color: #ff6616;
    }
  }

  .sec5 {
    height: 722px;
  }

  .swiper-main {
    width: 1200px;
    position: relative;
  }

  .swiper-box {
    width: 1068px;
    height: 700px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }

  .swiper-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 99999px;
  }

  .swiper-item {
    width: 1068px;
    overflow: hidden;
    height: 700px;
    float: left;
  }

  .member {
    float: left;
    margin-right: 14px;
    margin-bottom: 20px;
    &:nth-child(4n) {
      margin-right: 0;
    }

    dt {
      height: 284px;
      width: 256px;
    }

    dt > img {
      width: 100%;
      height: 100%;
    }

    dd {
      padding-left: 5px;
    }

    .name {
      font-size: 18px;
      color: #333;
      line-height: 1.5;
      font-weight: bold;
    }

    .title {
      font-size: 14px;
      color: #333;
      line-height: 1.5;
    }
  }
  .btn-prev {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    width: 40px;
    height: 60px;
    margin-top: -30px;
    cursor: pointer;
    &:before {
      box-sizing: content-box;
      position: absolute;
      left: 5px;
      top: 50%;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-bottom: 8px solid #d5d5d5;
      border-left: 8px solid #d5d5d5;
      transform: rotate(45deg);
      margin-top: -10px;
    }
  }

  .btn-next {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    width: 40px;
    height: 60px;
    margin-top: -30px;
    cursor: pointer;
    &:before {
      box-sizing: content-box;
      position: absolute;
      right: 5px;
      top: 50%;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-top: 8px solid #d5d5d5;
      border-right: 8px solid #d5d5d5;
      transform: rotate(45deg);
      margin-top: -10px;
    }
  }

  .sec6 {
    width: 100%;
    height: 813px;
    background: url(~static/pc/siemens/sec_6.png) no-repeat center top;
  }

  .sec7 {
    width: 100%;
    height: 887px;
    background: url(~static/pc/siemens/sec_7.png) no-repeat center top;
  }

  .lesson-list {
    width: 1160px;
    margin: 0 auto 100px;
    overflow: hidden;
  }

  .lesson-item {
    width: 340px;
    height: 400px;
    text-align: center;
    padding: 10px 24px;
    overflow: hidden;
    position: relative;
    float: left;
    margin-right: 70px;
    background-color: #ecf2f5;
  }

  .lesson-item:last-child {
    margin-right: 0;
  }

  .lesson-item .title {
    font-size: 24px;
    color: #333;
    line-height: 2;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .lesson-item .role {
    width: 218px;
    height: 218px;
    display: block;
    margin: 0 auto 20px;
    border-radius: 50%;
  }

  .lesson-item .summary {
    font-size: 16px;
    color: #666;
    line-height: 24px;
  }

  .lesson-item .detail {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    padding: 10px 24px;
    background-color: #00cfcb;
    background: linear-gradient(180deg, #00cfcb 0%, #0084b3 100%);
    transition: all 0.3s ease-in-out;
  }

  .lesson-item:hover .detail {
    top: 0;
  }

  .lesson-item .detail .title {
    color: #fff;
  }

  .lesson-item .detail .desc {
    font-size: 18px;
    color: #fff;
    line-height: 44px;
    margin-top: 70px;
  }

  .sec9 {
    width: 100%;
    height: 917px;
    background: url(~static/pc/siemens/sec_9.png) no-repeat center top;
  }

  .slider-wrapper {
    width: 268px;
    height: 582px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 157px;
    .swiper-wrapper {
      width: 99999px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
    .swiper-slide {
      width: 268px;
      height: 582px;
      float: left;
      img {
        display: block;
        width: 100%;
        height: 100%;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }
    .swiper-pagination-bullet {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 3px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
    }
    .swiper-pagination-bullet-active {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .sec10 {
    width: 100%;
    height: 654px;
    background: url(~static/pc/siemens/sec_10.png) no-repeat center top;
  }

  .sec10 .form {
    position: absolute;
    left: 170px;
    top: 85px;
    width: 300px;
  }

  .form .form-item {
    margin-bottom: 20px;
  }

  .form .input {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #fff;
    line-height: 38px;
    height: 40px;
    border: 1px solid #fff;
    background-color: transparent;
    padding: 0 10px;
  }

  .sec10 ::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  .form .btn-submit {
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: #fff;
    background-color: #ff7e16;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .dialog-sign {
    width: 800px;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    margin-left: -400px;
    margin-top: -240px;
    border-radius: 10px;
    overflow: hidden;
  }

  .dialog-head {
    position: relative;
    height: 48px;
    background-color: #0084b3;
    background: linear-gradient(0deg, #00cfcb 0%, #0084b3 100%);
  }

  .dialog-head .icon-close {
    position: absolute;
    right: 0px;
    top: 0;
    width: 48px;
    height: 48px;
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-size: 24px;
  }

  .dialog-body {
    overflow: hidden;
    background-color: #fff;
  }

  .dialog-sign .form {
    position: static;
    float: left;
    margin-left: 36px;
    margin-top: 32px;
    width: 300px;
  }

  .dialog-sign .desc {
    font-size: 12px;
    color: #666;
    line-height: 20px;
    margin-top: 15px;
  }

  .dialog-sign .qrcode {
    float: right;
    width: 409px;
    height: 328px;
    margin-top: 30px;
  }

  .dialog-body .form .input {
    color: #333;
    border-color: #ccc;
  }

  .dialog-appointment {
    width: 500px;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    margin-left: -250px;
    margin-top: -250px;
    border-radius: 10px;
    overflow: hidden;
  }

  .dialog-appointment .form {
    width: 300px;
    margin: 32px auto;
  }

  .dialog-appointment .desc {
    text-align: center;
    font-size: 12px;
    color: #666;
    line-height: 20px;
    margin-top: 15px;
  }

  .dialog-appointment .logo {
    width: 259px;
    height: 67px;
    display: block;
    margin: 20px auto 36px;
  }
}
</style>
